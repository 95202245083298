<template>
  <base-layout-two page-title="Edit Profile" :page-default-back-link="`/users/${$route.params.id}/profile`">
    <div class="padding-8">
      <v-form @submit="onSubmit">
        <ion-grid class="ion-no-padding grid-padding">
          <!-- Profile Information -->
          <ion-row>
            <ion-col size="12">
              <base-card title="⭐️ Profile Information">
                <ion-grid>
                  <ion-row>
                    <!-- Salutation -->
                    <ion-col size="12" size-lg="2">
                      <base-input label-text="Title">
                        <v-field name="title" v-slot="{ field }" v-model="user.title">
                          <select v-bind="field" class="regular-select">
                            <option value="Mrs.">Mrs.</option>
                            <option value="Ms.">Ms.</option>
                            <option value="Miss">Miss</option>
                            <option value="Mr.">Mr.</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="title" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <!-- First Name -->
                    <ion-col size="12" size-lg="3">
                      <base-input label-text="First Name *">
                        <v-field name="first_name" v-slot="{ field }" v-model="user.first_name" :rules="required">
                          <ion-input name="first_name" v-bind="field" placeholder="First Name" type="text"
                            autocapitalize="words"></ion-input>
                        </v-field>
                      </base-input>
                      <v-error-message name="first_name" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <!-- Last Name -->
                    <ion-col size="12" size-lg="3">
                      <base-input label-text="Last Name *">
                        <v-field name="last_name" v-slot="{ field }" v-model="user.last_name" :rules="required">
                          <ion-input name="last_name" v-bind="field" placeholder="Last Name" type="text"
                            autocapitalize="words"></ion-input>
                        </v-field>
                      </base-input>
                      <v-error-message name="last_name" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <!-- Pronouns -->
                    <ion-col size="12" size-lg="2">
                      <base-input label-text="Pronouns">
                        <v-field name="pronouns" v-slot="{ field }" v-model="user.profile.pronouns">
                          <select v-bind="field" class="regular-select">
                            <option value="">No preference</option>
                            <option value="She/her">She/her</option>
                            <option value="She/her">She/they</option>
                            <option value="He/him">He/him</option>
                            <option value="She/her">He/they</option>
                            <option value="They/them">They/them</option>
                          </select>
                        </v-field>
                      </base-input>
                    </ion-col>

                    <!-- Preferred Name -->
                    <ion-col size="12" size-lg="2">
                      <base-input label-text="Preferred Name">
                        <v-field name="alias" v-slot="{ field }" v-model="user.alias">
                          <ion-input name="alias" v-bind="field" placeholder="" type="text"
                            autocapitalize="words"></ion-input>
                        </v-field>
                      </base-input>
                    </ion-col>
                  </ion-row>

                  <!-- Baby Profile Info -->
                  <ion-row v-if="user.role === 'baby'">
                    <ion-col size="12" size-lg="3">
                      <base-input label-text="Due Date">
                        <v-field name="due_date" v-slot="{ field }" v-model="user.profile.baby_due_date">
                          <ion-input name="due_date" v-bind="field" placeholder="" type="date"></ion-input>
                        </v-field>
                      </base-input>
                      <v-error-message name="due_date" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <ion-col size="12" size-lg="3">
                      <base-input label-text="Birth Date">
                        <v-field name="birth_date" v-slot="{ field }" v-model="user.profile.birth_date">
                          <ion-input name="birth_date" v-bind="field" placeholder="" type="date"></ion-input>
                        </v-field>
                      </base-input>
                      <v-error-message name="birth_date" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <ion-col size="12" size-lg="3">
                      <base-input label-text="Delivery Type">
                        <v-field name="baby_delivery_type" v-slot="{ field }" v-model="user.profile.baby_delivery_type">
                          <select v-bind="field" class="regular-select">
                            <option value="natural">Natural</option>
                            <option value="c-section">C-Section</option>
                            <option value="other">Other</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="baby_delivery_type" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <ion-col size="12" size-lg="3">
                      <base-input label-text="Weight at Birth">
                        <v-field name="baby_birth_weight" v-slot="{ field }" v-model="user.profile.baby_birth_weight">
                          <ion-input name="baby_birth_weight" v-bind="field" type="text"></ion-input>
                        </v-field>
                      </base-input>
                      <v-error-message name="baby_birth_weight" class="error-message">
                      </v-error-message>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </base-card>
            </ion-col>

            <!-- Baby Feeding Information -->
            <ion-col size="12" v-if="user.role === 'baby'">
              <base-card title="🍼 Feeding">
                <ion-grid>
                  <ion-row>
                    <!-- Breast or Bottle -->
                    <ion-col size="12" size-lg="3">
                      <base-input label-text="Breast or Bottle">
                        <v-field name="baby_feeding_method" v-slot="{ field }" v-model="user.profile.baby_feeding_method">
                          <select v-bind="field" class="regular-select">
                            <option value="breast">Breast</option>
                            <option value="bottle">Bottle</option>
                            <option value="combination">Combination</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="baby_feeding_method" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <!-- Breast or Bottle -->
                    <ion-col size="12" size-lg="3">
                      <base-input label-text="Breastmilk or Formula">
                        <v-field name="baby_feeding_source" v-slot="{ field }" v-model="user.profile.baby_feeding_source">
                          <select v-bind="field" class="regular-select">
                            <option value="breastmilk">Breastmilk</option>
                            <option value="formula">Formula</option>
                            <option value="combination">Combination</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="baby_feeding_source" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <!-- Formula Brand -->
                    <ion-col size="12" size-lg="3">
                      <base-input label-text="Formula Brand">
                        <v-field name="baby_feeding_formula" v-slot="{ field }"
                          v-model="user.profile.baby_feeding_formula">
                          <select v-bind="field" class="regular-select">
                            <option value="boram formula">Boram formula</option>
                            <option value="other">Other</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="baby_feeding_formula" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <!-- Schedule or On Demand -->
                    <ion-col size="12" size-lg="3">
                      <base-input label-text="Schedule or On Demand">
                        <v-field name="baby_feeding_schedule" v-slot="{ field }"
                          v-model="user.profile.baby_feeding_schedule">
                          <select v-bind="field" class="regular-select">
                            <option value="schedule">Schedule</option>
                            <option value="on_demand">On Demand</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="baby_feeding_schedule" class="error-message">
                      </v-error-message>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </base-card>
            </ion-col>

            <!-- Baby Nights Information -->
            <ion-col size="12" size-lg="12" v-if="user.role === 'baby'">
              <base-card title="💤 Nights">
                <ion-grid>
                  <ion-row>
                    <!-- Sleep Location -->
                    <ion-col size="12" size-lg="6">
                      <base-input label-text="Sleep Location">
                        <v-field name="baby_night_sleep_location" v-slot="{ field }"
                          v-model="user.profile.baby_night_sleep_location">
                          <select v-bind="field" class="regular-select">
                            <option value="guest room">Guest Room</option>
                            <option value="nursery">Nursery</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="baby_night_sleep_location" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <!-- Feeding Location -->
                    <ion-col size="12" size-lg="6">
                      <base-input label-text="Feeding Location">
                        <v-field name="baby_night_feeding_location" v-slot="{ field }"
                          v-model="user.profile.baby_night_feeding_location">
                          <select v-bind="field" class="regular-select">
                            <option value="guest room">Guest Room</option>
                            <option value="nursery">Nursery</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="baby_night_feeding_location" class="error-message">
                      </v-error-message>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </base-card>
            </ion-col>

            <!-- Baby Diapering & Clothing Information -->
            <ion-col size="12" size-lg="6" v-if="user.role === 'baby'">
              <base-card title="🧦 Diapering & Clothing">
                <ion-grid>
                  <ion-row>
                    <!-- Diapering  -->
                    <ion-col size="12" size-lg="6">
                      <base-input label-text="Diapering">
                        <v-field name="baby_diapering" v-slot="{ field }" v-model="user.profile.baby_diapering">
                          <select v-bind="field" class="regular-select">
                            <option value="boram diapers">Boram diapers</option>
                            <option value="other">Other</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="baby_diapering" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <!-- Ointment -->
                    <ion-col size="12" size-lg="6">
                      <base-input label-text="Ointment">
                        <v-field name="baby_ointment" v-slot="{ field }" v-model="user.profile.baby_ointment">
                          <select v-bind="field" class="regular-select">
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="baby_ointment" class="error-message">
                      </v-error-message>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </base-card>
            </ion-col>

            <!-- Baby Care Information -->
            <ion-col size="12" size-lg="6" v-if="user.role === 'baby'">
              <base-card title="🧴 Other Care">
                <ion-grid>
                  <ion-row>
                    <!-- Clothing -->
                    <ion-col size="12" size-lg="6">
                      <base-input label-text="Clothing">
                        <v-field name="baby_clothing" v-slot="{ field }" v-model="user.profile.baby_clothing">
                          <select v-bind="field" class="regular-select">
                            <option value="boram clothing">
                              Boram clothing
                            </option>
                            <option value="other">Other</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="baby_clothing" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <!-- Pacifier  -->
                    <ion-col size="12" size-lg="6">
                      <base-input label-text="Pacifier">
                        <v-field name="baby_pacifier" v-slot="{ field }" v-model="user.profile.baby_pacifier">
                          <select v-bind="field" class="regular-select">
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </select>
                        </v-field>
                      </base-input>
                      <v-error-message name="baby_pacifier" class="error-message">
                      </v-error-message>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </base-card>
            </ion-col>

            <!-- Contact Information -->
            <ion-col size="12" v-if="user.role === 'mother'">
              <base-card title="📞 Contact">
                <ion-grid>
                  <ion-row>
                    <!-- Email -->
                    <ion-col size="12" size-lg="6">
                      <base-input label-text="Email">
                        <v-field name="email" v-slot="{ field }" v-model="user.email">
                          <ion-input name="email" v-bind="field" placeholder="Email" type="email"></ion-input>
                        </v-field>
                      </base-input>
                      <v-error-message name="email" class="error-message">
                      </v-error-message>
                    </ion-col>

                    <!-- Mobile Number -->
                    <ion-col size="12" size-lg="6">
                      <base-input label-text="Mobile Number">
                        <v-field name="mobile_number" v-slot="{ field }" v-model="user.mobile_number">
                          <ion-input name="mobile_number" v-bind="field" placeholder="555-555-5555"
                            type="tel"></ion-input>
                        </v-field>
                      </base-input>
                      <v-error-message name="mobile_number" class="error-message">
                      </v-error-message>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </base-card>
            </ion-col>

            <!-- Care Information -->
            <ion-col size="12" v-if="user.role === 'mother'">
              <base-card title="❤️ Care">
                <ion-row>
                  <!-- Due Date -->
                  <ion-col size="12" size-lg="6">
                    <base-input label-text="Due Date">
                      <v-field name="due_date" v-slot="{ field }" v-model="user.profile.due_date">
                        <ion-input name="due_date" v-bind="field" placeholder="" type="date"></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="due_date" class="error-message"></v-error-message>
                  </ion-col>

                  <!-- Allergies -->
                  <ion-col size="12" size-lg="6">
                    <base-input label-text="Allergies">
                      <v-field name="allergies" v-slot="{ field }" v-model="user.profile.allergies">
                        <ion-input name="allergies" v-bind="field" placeholder="" type="text" autocapitalize
                          autocorrect></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="allergies" class="error-message">
                    </v-error-message>
                  </ion-col>

                  <!-- Dietary Preference -->
                  <ion-col size="12" size-lg="6">
                    <base-input label-text="Dietary Preference">
                      <v-field name="dietary_preferences" v-slot="{ field }" v-model="user.profile.dietary_preferences">
                        <select v-bind="field" class="regular-select">
                          <option value="regular">Regular</option>
                          <option value="gluten-free">Gluten-Free</option>
                          <option value="vegetarian">Vegetarian</option>
                          <option value="kosher">Kosher</option>
                        </select>
                      </v-field>
                    </base-input>
                    <v-error-message name="dietary_preferences" class="error-message">
                    </v-error-message>
                  </ion-col>

                  <!-- Prescribed Medication -->
                  <ion-col size="12" size-lg="6">
                    <base-input label-text="Prescribed Medications">
                      <v-field name="prescribed_medications" v-slot="{ field }"
                        v-model="user.profile.prescribed_medications">
                        <ion-input name="prescribed_medications" v-bind="field" placeholder="" type="text" autocapitalize
                          autocorrect></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="prescribed_medications" class="error-message">
                    </v-error-message>
                  </ion-col>

                  <!-- OTC Medications -->
                  <ion-col size="12" size-lg="6">
                    <base-input label-text="OTC Medications">
                      <v-field name="otc_medications" v-slot="{ field }" v-model="user.profile.otc_medications">
                        <ion-input name="otc_medications" v-bind="field" placeholder="" type="text" autocapitalize
                          autocorrect></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="otc_medications" class="error-message">
                    </v-error-message>
                  </ion-col>
                </ion-row>
              </base-card>
            </ion-col>

            <!-- Support Person -->
            <ion-col size="12" size-lg="6" v-if="user.role === 'mother'">
              <base-card title="Support Person">
                <ion-row>
                  <!-- Support Person Name -->
                  <ion-col>
                    <base-input label-text="Support Person Name">
                      <v-field name="support_person_name" v-slot="{ field }" v-model="user.profile.support_person_name">
                        <ion-input name="support_person_name" v-bind="field" placeholder="Support Person Name" type="text"
                          autocapitalize="words" autocorrect></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="support_person_name" class="error-message">
                    </v-error-message>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <!-- Support Person Number -->
                  <ion-col>
                    <base-input label-text="Support Person Number">
                      <v-field name="support_person_number" v-slot="{ field }"
                        v-model="user.profile.support_person_number">
                        <ion-input name="support_person_number" v-bind="field" placeholder="Support Person Number"
                          type="tel"></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="support_person_number" class="error-message">
                    </v-error-message>
                  </ion-col>
                </ion-row>
              </base-card>
            </ion-col>

            <ion-col size="12" size-lg="6" v-if="user.role === 'mother'">
              <base-card title="Emergency Contact">
                <!-- Emergency Contact -->
                <ion-row>
                  <!-- Emergency Contact Name -->
                  <ion-col size="12" size-lg="6">
                    <base-input label-text="Emergency Contact Name">
                      <v-field name="emergency_contact_name" v-slot="{ field }"
                        v-model="user.profile.emergency_contact_name">
                        <ion-input name="emergency_contact_name" v-bind="field" placeholder="Emergency Contact Name"
                          type="text" autocapitalize="words" autocorrect></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="emergency_contact_name" class="error-message">
                    </v-error-message>
                  </ion-col>

                  <!-- Emergency Contact Relationship -->
                  <ion-col size="12" size-lg="6">
                    <base-input label-text="Emergency Contact Relationship">
                      <v-field name="emergency_contact_relationship" v-slot="{ field }"
                        v-model="user.profile.emergency_contact_relationship">
                        <ion-input name="emergency_contact_relationship" v-bind="field"
                          placeholder="Spouse, Mother, Father, etc." type="text" autocapitalize="words"
                          autocorrect></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="emergency_contact_relationship" class="error-message">
                    </v-error-message>
                  </ion-col>

                  <!-- Emergency Contact Number -->
                  <ion-col>
                    <base-input label-text="Emergency Contact Number">
                      <v-field name="emergency_contact_number" v-slot="{ field }"
                        v-model="user.profile.emergency_contact_number">
                        <ion-input name="emergency_contact_number" v-bind="field" placeholder="Emergency Contact Number"
                          type="tel"></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="emergency_contact_number" class="error-message">
                    </v-error-message>
                  </ion-col>
                </ion-row>
              </base-card>
            </ion-col>

            <!-- Boram Anywhere -->
            <ion-col size="12" size-lg="12" v-if="user.role === 'mother'">
              <base-card title="📱 Boram Anywhere">
                <ion-row>
                  <!-- Start -->
                  <ion-col size="6">
                    <base-input label-text="Start">
                      <v-field name="ba_start" v-slot="{ field }" v-model="user.profile.ba_start">
                        <ion-input name="ba_start" v-bind="field" placeholder="" type="date"></ion-input>
                      </v-field>
                    </base-input>
                  </ion-col>

                  <!-- End  -->
                  <ion-col size="6">
                    <base-input label-text="End">
                      <v-field name="ba_end" v-slot="{ field }" v-model="user.profile.ba_end">
                        <ion-input name="ba_end" v-bind="field" placeholder="" type="date"></ion-input>
                      </v-field>
                    </base-input>
                  </ion-col>
                </ion-row>
              </base-card>
            </ion-col>

            <!-- OBGYN -->
            <ion-col size="12" size-lg="6" v-if="user.role === 'mother'">
              <base-card title="OBGYN">
                <ion-row>
                  <!-- OBGYN Name -->
                  <ion-col>
                    <base-input label-text="OBGYN Name">
                      <v-field name="obgyn_name" v-slot="{ field }" v-model="user.profile.obgyn_name">
                        <ion-input name="obgyn_name" v-bind="field" placeholder="OBGYN Name" type="text"
                          autocapitalize="words" autocorrect></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="obgyn_name" class="error-message">
                    </v-error-message>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <!-- OBGYN Number -->
                  <ion-col>
                    <base-input label-text="OBGYN Number">
                      <v-field name="obgyn_number" v-slot="{ field }" v-model="user.profile.obgyn_number">
                        <ion-input name="obgyn_number" v-bind="field" placeholder="OBGYN Number" type="tel"
                          autocapitalize="words" autocorrect></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="obgyn_number" class="error-message">
                    </v-error-message>
                  </ion-col>
                </ion-row>
              </base-card>
            </ion-col>

            <!-- Pediatrician -->
            <ion-col size="12" size-lg="6" v-if="user.role === 'baby'">
              <base-card title="Pediatrician">
                <ion-row>
                  <!-- Pediatrician Name -->
                  <ion-col>
                    <base-input label-text="Pediatrician Name">
                      <v-field name="pediatrician_name" v-slot="{ field }" v-model="user.profile.pediatrician_name">
                        <ion-input name="pediatrician_name" v-bind="field" placeholder="" type="text"
                          autocapitalize="words" autocorrect></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="pediatrician_name" class="error-message">
                    </v-error-message>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <!-- Pediatrician Number -->
                  <ion-col>
                    <base-input label-text="Pediatrician Number">
                      <v-field name="pediatrician_number" v-slot="{ field }" v-model="user.profile.pediatrician_number">
                        <ion-input name="pediatrician_number" v-bind="field" placeholder="" type="tel"></ion-input>
                      </v-field>
                    </base-input>
                    <v-error-message name="pediatrician_number" class="error-message">
                    </v-error-message>
                  </ion-col>
                </ion-row>
              </base-card>
            </ion-col>            

            <!-- General Notes -->
            <ion-col size="12">
              <base-card title="General Notes">
                <ion-row>
                  <!-- General Notes -->
                  <ion-col>
                    <base-input label-text="General Notes">
                      <v-field name="general_notes" v-slot="{ field }" v-model="user.general_notes">
                        <ion-textarea name="general_notes" rows="1" :auto-grow="true" v-bind="field"
                          placeholder="General Notes" autocapitalize autocorrect></ion-textarea>
                      </v-field>
                    </base-input>
                    <v-error-message name="general_notes" class="error-message">
                    </v-error-message>
                  </ion-col>
                </ion-row>
              </base-card>
            </ion-col>
          </ion-row>

          <ion-row>
            <!-- Type -->
            <ion-col>
              <base-card title="User Type">
                <base-input label-text="Type">
                  <v-field name="role" v-slot="{ field }" v-model="user.role" :rules="required">
                    <select v-bind="field" class="regular-select">
                      <option value="mother">Mother</option>
                      <option value="baby">Baby</option>
                      <option value="support">Support Person</option>
                      <option value="visitor">Visitor</option>
                    </select>
                  </v-field>
                </base-input>
                <v-error-message name="role" class="error-message">
                </v-error-message>
              </base-card>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-button type="submit" expand="block" class="margin-bottom-16">
          Save
        </ion-button>
      </v-form>
    </div>
  </base-layout-two>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonTextarea,
  IonButton,
} from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonTextarea,
    IonButton,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  data() {
    return {
      user: {
        profile: {},
      },
      required: yup.string().required(),
      emailRequired: yup.string().email().required(),
    };
  },

  ionViewWillEnter() {
    this.fetchUser();
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async fetchUser() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/users/${this.$route.params.id}`)
        .then((response) => {
          this.user = response.data.user;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async onSubmit() {
      const config = {
        method: "patch",
        url: `${process.env.VUE_APP_API}/users/${this.$route.params.id}`,
        data: this.user,
      };

      // Fix Birth Date format
      if (config.data.birthdate)
        config.data.birthdate = new Date(config.data.birthdate)
          .toISOString()
          .replace("T", " ")
          .replace("Z", "");

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          this.user = response.data.user;

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          // this.loadToast({ message: "Profile Updated", color: "secondary" });

          this.$router.back();
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>